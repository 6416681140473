<script lang="ts" setup>
import {useSettingsStore} from "~/stores/settings";
import TitleInGrid from "~/components/Redmonkey/Base/TitleInGrid.vue";
import {useElementor, useWp} from "~/composables/Redmonkey";
import type {TestimonialItem} from "~/integration/wordpress/types";
import Item from "~/components/Redmonkey/Testimonials/Item.vue";
import DecorInGrid from "~/components/Redmonkey/Base/DecorInGrid.vue";
import InfiniteVertical from "~/components/Redmonkey/Base/InfiniteVertical.vue";

const settingsStore = useSettingsStore();

const {
  getSettingsValue: getGeneralSettingValue,
  getMediaHeightFromMedia,
  getMediaUrlFromMedia,
  getMediaWidthFromMedia,
  getMediaAltFromMedia
} = useWp()

const {
  getSettingsValue,
  isThatDefault
} = useElementor();

const props = defineProps({
  element: Object
})

const getTitle = () => {
  return getSettingsValue(props.element, 'title') || 'Відгуки';
}

const getItems = () => {
  if (!isThatDefault(props.element, 'use_default')) {
    return getSettingsValue(props.element, 'testimonialsItems');
  }
  return getGeneralSettingValue(settingsStore.redmonkeySettings, 'testimonialsItems', 'testimonials');
}

const getPhoto = (item: TestimonialItem) => {
  if (!isThatDefault(props.element, 'use_default')) {
    return {
      url: item.photo.url,
      alt: item.photo.alt,
      width: 'auto',
      height: 'auto'
    }
  }

  const image = item.photo.node;

  return {
    url: getMediaUrlFromMedia(image),
    alt: getMediaAltFromMedia(image),
    width: getMediaWidthFromMedia(image),
    height: getMediaHeightFromMedia(image)
  }
}

function splitArrayIntoChunks(arr: [], chunkSize = 3) {
  if (!arr) return [];

  const result = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    result.push(arr.slice(i, i + chunkSize));
  }
  return result;
}
</script>

<template>
  <div class="testimonials">
    <div class="testimonials-content flex wrap">
      <TitleInGrid :title="getTitle()"/>
      <div class="testimonials-items flex wrap hide-mobile">
        <template v-for="(testimonials, index) in splitArrayIntoChunks(getItems())" :key="index">
          <InfiniteVertical
              :classes="`testimonials-part testimonials-part_${index}`"
              parent-class="testimonials"
              :scroll-direction="index === 1 ? 'up' : 'down'"
          >
            <DecorInGrid v-if="index === 1" title="а це тільки частина"/>
            <template v-for="item in testimonials" :key="item.name">
              <Item v-if="item" :item="item" :get-photo="getPhoto"/>
            </template>
          </InfiniteVertical>
        </template>
      </div>
      <div class="testimonials-items testimonials-items-mobile hide-desktop hide-ipad-p hide-medium">
        <Swiper :slides-per-view="1.3" :loop="true" :space-between="15">
          <SwiperSlide v-for="item in getItems()" :key="item.name">
            <Item v-if="item" :item="item" :get-photo="getPhoto"/>
          </SwiperSlide>
        </Swiper>
        <DecorInGrid title="а це тільки частина"/>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import "assets/css/source/components/widgets/testimonials";
</style>