<script lang="ts" setup>
import { ref, onMounted, watch } from 'vue';

const scrollContainer = ref<HTMLElement | null>(null);

const props = defineProps({
  classes: {
    type: String,
    default: '',
  },
  parentClass: {
    type: String,
    default: 'scroll-parent',
  },
  scrollDirection: {
    type: String,
    default: 'down', // 'down' or 'up'
  },
  scrollSpeed: {
    type: Number,
    default: 1, // Adjust this value to control scroll speed
  }
});

const fixParentHeight = () => {
  const parent = scrollContainer.value?.closest(`.${props.parentClass}`) as HTMLElement | null;
  if (!parent) return false;

  if (scrollContainer.value) {
    scrollContainer.value.style.maxHeight = `${parent.offsetHeight}px`;
  }

  return parent;
};

const duplicateItems = () => {
  const parent = scrollContainer.value;
  if (!parent) return;

  const items = Array.from(parent.children);
  const totalDuplicates = 3; // Number of times to duplicate the content

  for (let i = 0; i < totalDuplicates; i++) {
    items.forEach(item => {
      parent.appendChild(item.cloneNode(true));
    });
  }
};

const startScrolling = () => {
  const parent = scrollContainer.value;
  if (!parent) return;

  let lastScrollTop = 0;
  const totalHeight = parent.scrollHeight / 3; // Adjust based on duplications

  const updateScroll = () => {
    if (props.scrollDirection === 'down') {
      if (parent.scrollTop >= totalHeight) {
        parent.scrollTop = 0;
      } else {
        parent.scrollTop += props.scrollSpeed;
      }
    } else if (props.scrollDirection === 'up') {
      if (parent.scrollTop <= 0) {
        parent.scrollTop = totalHeight;
      } else {
        parent.scrollTop -= props.scrollSpeed;
      }
    }

    requestAnimationFrame(updateScroll);
  };

  updateScroll();
};

onMounted(() => {
  if (!process.client) return;

  const parent = fixParentHeight();
  if (!parent) return;

  duplicateItems();
  startScrolling();
});
</script>



<template>
  <div class="infinite-vertical"
       :class="[classes, `scroll-${props.scrollDirection}`]"
       ref="scrollContainer"
  >
    <slot />
  </div>
</template>

<style lang="scss">
  @import "assets/css/source/components/infinite_vertical";
</style>

